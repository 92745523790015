<template>
  <div :class="$style.settings">
    <div :class="$style.wrapper">
      <el-button size="small" type="primary" @click="submitForm()"
        >Сохранить</el-button
      >
    </div>
    <el-form :model="form" ref="form" label-position="left" label-width="14rem">
      <h2 :class="$style.name">Коэффициенты бонусных транзакций</h2>
      <div :class="$style.ratios">
        <el-form-item label="Коэффициент начисления" prop="bonusAccrualRatio">
          <el-input-number
            v-model.number="form.bonusAccrualRatio"
            controls-position="right"
            :precision="3"
            :step="0.001"
            :min="0.001"
          />
        </el-form-item>
        <el-form-item label="Коэффициент списания" prop="bonusWriteOffRatio">
          <el-input-number
            v-model.number="form.bonusWriteOffRatio"
            controls-position="right"
            :precision="3"
            :step="0.001"
            :min="0.001"
          />
        </el-form-item>
        <el-form-item label="Кол-во дней до сгорания" prop="bonusExpiration">
          <el-input-number
            v-model.number="form.bonusExpiration"
            controls-position="right"
            :min="1"
          />
        </el-form-item>
        <el-form-item
          label="Макс. списание (% от заказа)"
          prop="bonusMaxWriteOffCount"
        >
          <el-input-number
            v-model.number="form.bonusMaxWriteOffCount"
            controls-position="right"
            :min="1"
            :max="100"
          />
        </el-form-item>
        <el-form-item
          label="Мин. цена заказа для стандартной доставки"
          prop="minOrderPriceForDellivery"
        >
          <el-input-number
            v-model.number="form.minOrderPriceForDelivery"
            controls-position="right"
            :step="10"
            :min="0"
          />
        </el-form-item>
        <el-form-item
          label="Мин. цена заказа для экспресс доставки"
          prop="minOrderPriceForExpressDelivery"
        >
          <el-input-number
            v-model.number="form.minOrderPriceForExpressDelivery"
            controls-position="right"
            :step="10"
            :min="0"
          />
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'

export default {
  async created() {
    await this.getPaymentSettings()
  },
  data() {
    return {
      form: {
        bonusAccrualRatio: 0,
        bonusWriteOffRatio: 0,
        bonusExpiration: 0,
        bonusMaxWriteOffCount: 0,
        minOrderPriceForDelivery: 0,
        minOrderPriceForExpressDelivery: 0
      }
    }
  },
  methods: {
    async getPaymentSettings() {
      const loading = this.$loading({
        lock: true
      })

      const { value, error } =
        await delivery.PaymentServiceCore.PaymentSettingsActions.get()
      loading.close()

      if (error) return

      this.form = value
    },
    async submitForm() {
      const loading = this.$loading({
        lock: true
      })

      const result =
        await delivery.PaymentServiceCore.PaymentSettingsActions.save(this.form)
      loading.close()

      if (result.error) {
        alert('Ошибка редактирования настроек: ' + result.error)
        return
      }

      alert('Настройки успешно изменены')

      this.getPaymentSettings()
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';
.settings {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
  }
  .ratios {
    display: flex;
    flex-direction: column;
  }
  .name {
    margin-bottom: 1rem;
    margin-left: 1.25rem;
  }
}
</style>
